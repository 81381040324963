@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
    --accent-color: rgba(255, 116, 124, 1);
    --dark-gray: #7C7C7C;
}

body {
    background: rgba(248, 248, 248, 1);
    Font-family: Poppins;
    margin:0px;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--dark-gray);
}

.navbar {
    background: white;
    box-shadow: 0px .15em .7em rgba(0, 0, 0, 0.205);
    padding: .5em;
    position:fixed;
    width: 100vw;
    top: 0;
    z-index: 10;
}

.name {
    font-family: Poppins;
    font-style: italic;
    font-weight: bold;
    line-height: 96%;

    display: flex;

    color: var(--dark-gray);
    text-decoration: none;
    float: left;
}

.navigation {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3em;
    line-height: 96%;
    padding:0px;

    color: var(--dark-gray);
    text-decoration: none;
    list-style-type: none;
    justify-content: space-between;
}

a:hover {
    color: #a3a2a2;
  }

ul.navigation {
    list-style-type: none;
    overflow: hidden;
}

li {
    float: right;
    margin: 0em 2em;
}

li a {
    float: right;
    display: block;
    padding: 0em 0em;
}

.active {
    color: var(--accent-color);
    float: right;
    padding: 0em 0em;
}

h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12em;
    line-height: 80%;
    align-items: center;
    text-align: center;
    padding-top: 1em;
    background-color: #FF747C;
    background-image: linear-gradient(180deg, #FF747C 25.88%, #FFC48E 75.22%);
    background-size: 200%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-clip: text;
}

h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 125%;

    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;

    color: var(--dark-gray);

    padding: 10rem;
    margin-bottom: -1.2em;
}

h3 {
    grid-column: 1;
    grid-row: 1;
}

h3 a{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 250%;


    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    margin: 0;
    

    background-color: #FF747C;
    background-image: linear-gradient(180deg, #FF747C 0%, #FFC48E 100%);
    background-size: 200%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-clip: text;
    grid-column: 1;
    grid-row: 1;
}

h5 {
    grid-column: 1;
    grid-row: 1;
}

h5 a{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 250%;


    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    margin: 0;
    

    color: white;
    grid-column: 1;
    grid-row: 1;
}

.about-me-button {
    display: grid;
  place-items: center;
  padding-bottom: 7em;
  padding-left: 2em;
  padding-right: 2em;
}

.side-buttons {
    display: flex;
    justify-content: center;
    
}

.other-nav {
    grid-column: 1;
    grid-row: 1;
    width: 18rem;
    height: 59px;
    left: 578px;
    top: 1607px;

    background: linear-gradient(180deg, #FF747C 0%, #FFC48E 100%);
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.08);
    border-radius: 30px;

    z-index: -1;
    float: right;
}
.about-me-nav {
    grid-column: 1;
    grid-row: 1;
    width: 18rem;
    height: 59px;
    left: 578px;
    top: 1607px;

    background: #FFFFFF;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.08);
    border-radius: 30px;

    z-index: -1;
}

h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 3em;
    line-height: 80%;

    align-items: center;
    text-align: left;
    letter-spacing: 0.1em;
    padding-left: 2em;
    top: 4.5em;
    padding-top: .5em;
    padding-bottom: .1em;

    background-color: #FF747C;
    background-image: linear-gradient(180deg, #FF747C 2.27%, #FFC48E 100%);
    background-size: 200%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-clip: text;

}

.featured-projects {
    margin: 0rem 5rem 0rem 5rem;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1rem;
    /*
    top: -2em;
    padding-bottom: 10em;
    padding-left: 6em;
    */
}

#viewAll {
    font-size: 1.5rem;
    align-items: center;
    text-align: left;
    font-family: Poppins;
    padding-left: 5.5rem;
    font-style: normal;
    font-weight: 500;
    color: #ffb28e;
    margin-top: 4rem;
}

#viewAll:hover {
    color: #facea5;
}

.featured-projects a {
    font-size: 1rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    color: #ffb28e;
}

.featured-projects a:hover {
    color: #facea5;
}

.featured-projects p {
    font-size: 1rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    color: #a3a2a2;
    margin: 1.5rem;
}

h6 {
    background-color: #FF747C;
    background-image: linear-gradient(180deg, #FF747C 2.27%, #FFC48E 100%);
    background-size: 300%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-clip: text;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    text-align: center;
    display: block;
    margin-top: 1rem;
    margin-bottom: 0rem;
}

.project h6 {
    font-size: 1rem;;
    z-index: 10;
    margin-top: 0rem;

}
.project a {
    font-size: 1rem;
}

.project details {
    font-size: 1rem;
    color: var(--dark-gray);
}

.project p {
    padding: 0rem 2rem 0rem 2rem;
}

.project img {
    width: 80%;
}

.project {

        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 100%;
        text-align: center;
        display: block;
        padding: 1rem;
    
        width: 100%;
        border-radius: 20px;
        color: #ffb28e;
        justify-content: center;
        align-items: center;
        margin-bottom: 8rem;

}

iframe {
    margin-bottom: -1.8em;
    padding-bottom: -1em;
}

.projecttext {
    width: 412px;
    height: 566px;
    overflow: hidden;
    margin-bottom: 5em;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    float: left;
    margin-left: 0em;
    margin-right: 1em;
    color: #ffb28e;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.feature {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    text-align: center;
    display: block;
    padding: 1rem;

    width: 25rem;
    height: 566px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    color: #ffb28e;
    justify-content: center;
    align-items: center;
}

.feature img {
    border-radius: 0rem;
    margin-top: 2rem;
    height: 35%;
}

.background-circles {
    width: 100vw;
    overflow: hidden;
}

.ellipse1 {

position: absolute;
width: 534px;
height: 534px;
left: -161px;
top: -142px;
border-radius: 50%;
z-index: -2;

background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse2 {

position: absolute;
width: 314px;
height: 314px;
left: -51px;
top: -32px;

background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
border-radius: 50%;
z-index: -2;
}
.ellipse13 {

position: absolute;
width: 219px;
height: 219px;
left: -22px;
top: 605px;

background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
border-radius: 50%;
z-index: -2;
}
.ellipse14 {

position: absolute;
width: 129px;
height: 129px;
left: 23px;
top: 650px;

background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
border-radius: 50%;
z-index: -2;
}
.ellipse15 {

position: absolute;
width: 219px;
height: 219px;
left: -109px;
top: 2180px;

background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
border-radius: 50%;
z-index: -2;
}
.ellipse16 {

position: absolute;
width: 129px;
height: 129px;
left: -64px;
top: 2225px;

background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
border-radius: 50%;
z-index: -2;
}
.ellipse21 {

position: absolute;
width: 219px;
height: 219px;
left: 1281px;
top: 1922px;

background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
border-radius: 50%;
z-index: -2;
}
.ellipse22 {

position: absolute;
width: 129px;
height: 129px;
left: 1326px;
top: 1967px;
border-radius: 50%;
z-index: -2;

background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse23 {

position: absolute;
width: 272px;
height: 272px;
left: -109px;
top: 1637px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse24 {

position: absolute;
width: 160px;
height: 160px;
left: -53px;
top: 1693px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse17 {

position: absolute;
width: 351px;
height: 351px;
left: 1217px;
top: 2267px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse18 {

position: absolute;
width: 207px;
height: 207px;
left: 1289px;
top: 2339px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse11 {

position: absolute;
width: 534px;
height: 534px;
left: 1011px;
top: 44px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);

}
.ellipse12 {

position: absolute;
width: 314px;
height: 314px;
left: 1121px;
top: 154px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse9 {

position: absolute;
width: 142px;
height: 142px;
left: 302px;
top: 824px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);

}
.ellipse10 {

position: absolute;
width: 82px;
height: 82px;
left: 332px;
top: 854px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse7 {

position: absolute;
width: 296px;
height: 296px;
left: 49px;
top: 764px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse8 {

position: absolute;
width: 176px;
height: 176px;
left: 109px;
top: 824px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse5 {

position: absolute;
width: 234px;
height: 234px;
left: 1109px;
top: 0px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);

}
.ellipse6 {

position: absolute;
width: 138px;
height: 138px;
left: 1157px;
top: 48px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse3 {

position: absolute;
width: 534px;
height: 534px;
left: 999px;
top: 720px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse4 {

position: absolute;
width: 314px;
height: 314px;
left: 1109px;
top: 830px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse19 {

position: absolute;
width: 439px;
height: 439px;
left: 50px;
top: 2223px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse20 {

position: absolute;
width: 259px;
height: 259px;
left: 140px;
top: 2313px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.section-header {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 8em;
    line-height: 80%;
    text-align: center;
    padding: .3em;
    margin-top: 1em;

    background-color: #FF747C;
    background-image: linear-gradient(180deg, #FF747C 25.88%, #FFC48E 75.22%);
    background-size: 200%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-clip: text;

}

.greeting {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 2em;
    line-height: 125%;

    display: flex;
    text-align: left;
    float: right;
    letter-spacing: 0.2em;

    color: #FFC48E;
    padding-left: 2em; 
}

.bio {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 8em;
    padding-top: 4em;
    padding-bottom: 0em;
}

.content {
    display: grid;
}

.subheading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 2em;
    line-height: 176%;

    display: flex;
    align-items: center;
    letter-spacing: 0.2em;

    background-color: #FF747C;
    background-image: linear-gradient(180deg, #FF747C 0%, #FFC48E 100%);
    background-size: 200%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-clip: text;

    padding: .1em;
    padding-left: 0;

}

.right-col {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 222%;

    text-align: left;
    letter-spacing: 0.2em;

    color: #7C7C7C;
    grid-column: 2;
    grid-row: 1;
    padding: 5em;
    margin-right: 3em;
}

.left-col {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 222%;

    text-align: left;
    letter-spacing: 0.2em;

    color: #7C7C7C;
    grid-column: 1;
    grid-row: 1;
    padding: 5em;
    margin-left: 3em;
}

.bio img {
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
}

* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  width: 25em;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

.mySlides img {
    width: 100%;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: var(--dark-gray);
}

/* Number text (1/3 etc) */
.numbertext {
  color: var(--dark-gray);
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* .active, .dot:hover {
  background-color: #717171;
} */


.ellipse25 {
position: absolute;
width: 414px;
height: 414px;
left: 1182px;
top: -95px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse26 {
position: absolute;
width: 244px;
height: 244px;
left: 1267px;
top: -10px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse29 {

position: absolute;
width: 414px;
height: 414px;
left: -102px;
top: -163px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);

}
.ellipse30 {

position: absolute;
width: 244px;
height: 244px;
left: -17px;
top: -78px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse33 {

position: absolute;
width: 414px;
height: 414px;
left: 1159px;
top: 2189px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse34 {

position: absolute;
width: 244px;
height: 244px;
left: 1244px;
top: 2274px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse31 {

position: absolute;
width: 265px;
height: 265px;
left: -160px;
top: 1759px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse32 {

position: absolute;
width: 157px;
height: 157px;
left: -106px;
top: 1813px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}

.ellipse27 {

position: absolute;
width: 244px;
height: 244px;
left: 1060px;
top: -70px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);

}
.ellipse28 {

position: absolute;
width: 144px;
height: 144px;
left: 1110px;
top: -20px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse35 {

position: absolute;
width: 209px;
height: 209px;
left: 1101px;
top: 319px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse36 {

position: absolute;
width: 125px;
height: 125px;
left: 1143px;
top: 361px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse37 {

position: absolute;
width: 299px;
height: 299px;
left: -71px;
top: 2219px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse38 {

position: absolute;
width: 179px;
height: 179px;
left: -11px;
top: 2279px;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
border-radius: 50%;
z-index: -2;
}

.ellipse39 {

position: absolute;
width: 265px;
height: 265px;
left: 1392px;
top: 172px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse40 {

position: absolute;
width: 157px;
height: 157px;
left: 1446px;
top: 226px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}
.ellipse41 {

position: absolute;
width: 351px;
height: 351px;
left: -143px;
top: -49px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse42 {

position: absolute;
width: 207px;
height: 207px;
left: -71px;
top: 23px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse43 {

position: absolute;
width: 351px;
height: 351px;
left: -143px;
top: 2823px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse44 {
border-radius: 50%;
z-index: -2;
position: absolute;
width: 207px;
height: 207px;
left: -71px;
top: 2895px;

background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;
}

.ellipse45 {

position: absolute;
width: 223px;
height: 223px;
left: 1389px;
top: 2672px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);

}
.ellipse46 {

position: absolute;
width: 131px;
height: 131px;
left: 1435px;
top: 2718px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}
.ellipse47 {

position: absolute;
width: 351px;
height: 351px;
left: 1217px;
top: 2767px;
border-radius: 50%;
z-index: -2;
background: #FFFFFF;
box-shadow: 0px 4px 8px 12px rgba(255, 255, 255, 0.51), inset 0px 4px 30px 10px rgba(0, 0, 0, 0.08);
}

.ellipse48 {

position: absolute;
width: 207px;
height: 207px;
left: 1289px;
top: 2839px;
border-radius: 50%;
z-index: -2;
background: #F8F8F8;
box-shadow: 0px 4px 8px 12px rgba(0, 0, 0, 0.04), inset 0px 4px 30px 10px #FFFFFF;

}



.projects {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
    margin: auto;
}

.project-grid {
    padding: 5em;
}
/*
@media only screen and (max-width: 1100px) {
    [class*="featured-projects"] {

        display: block;
    }
   
    [class*="project"] {
        width: 27em;
    height: 35em;
    margin-bottom: 5em;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    margin-left: 1em;
    margin-right: 1em;
    }
}*/

  .threeCol {
    margin: 0rem auto 3rem auto;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: auto;
    column-gap: 2%;
    width: 100%;
    padding: 0 2% 0 2%;
  }

  .feature .macbook {
    margin: 2rem auto 0rem auto;
    width: 90%;
    max-width: 500px;
  }

  .macbook {
      width: 90%;
      margin: 0rem auto 0rem auto;
  }
  
  .screen {
    background: #000;
    border-radius: 3% 3% 0.5% 0.5% / 5%;
    margin: 0 auto;
    /* for aspect ratio */
    position: relative;
    width: 80%;
  }
  
  .screen:before {
    border: 2px solid #cacacc;
    border-radius: 3% 3% 0.5% 0.5% / 5%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.8) inset, 0 0 1px 2px rgba(255, 255, 255, 0.3) inset;
    content: "";
    display: block;
    /* for aspect ratio ~67:100 */
    padding-top: 67%;
  }
  
  .screen:after {
    content: "";
    border-top: 2px solid rgba(255, 255, 255, 0.15);
    position: absolute;
    bottom: 0.75%;
    left: 0.5%;
    padding-top: 1%;
    width: 99%;
  }
  
  .viewport {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: 4.3% 3.2%;
    background: #333;
  }
  
  .base {
    /* for aspect ratio */
    position: relative;
    /*width: 100%;*/
  }
  
  .base:before {
    content: "";
    display: block;
    /* for aspect ratio ~33:1000*/
    padding-top: 3.3%;
    /* stylin */
    background: linear-gradient(#eaeced, #edeef0 55%, #fff 55%, #8a8b8f 56%, #999ba0 61%, #4B4B4F 84%, #262627 89%, rgba(0, 0, 0, .01) 98%);
    border-radius: 0 0 10% 10%/ 0 0 50% 50%;
  }
  
  .base::after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.8) 0.5%, rgba(0, 0, 0, 0.4) 3.3%, transparent 15%, rgba(255, 255, 255, 0.8) 50%, transparent 85%, rgba(0, 0, 0, 0.4) 96.7%, rgba(255, 255, 255, 0.8) 99.5%, rgba(0, 0, 0, 0.5) 100%);
    content: "";
    height: 53%;
    position: absolute;
    top: 0;
    /*width: 100%;*/
  }
  
  .notch {
    background: #ddd;
    border-radius: 0 0 7% 7% / 0 0 95% 95%;
    box-shadow: -5px -1px 3px rgba(0, 0, 0, 0.2) inset, 5px -1px 3px rgba(0, 0, 0, 0.2) inset;
    margin-left: auto;
    margin-right: auto;
    margin-top: -3.5%;
    z-index: 2;
    /* for aspect ratio */
    position: relative;
    width: 14%;
  }
  
  .notch:before {
    content: "";
    display: block;
    /* for aspect ratio ~1:10 */
    padding-top: 10%;
  }
  
  
  /* Screenshot Hover Scroll Additions
  -------------------------------------------------------------- */
  
  .macbook .viewport {
    transition: background-position 10s ease;
    background-position: 0 0;
    background-size: 100% auto;
    overflow: hidden;
    cursor: pointer;
  }
  
  .macbook:hover .viewport {
    background-position: 0 100%;
  }
  
  .macbook .viewport:after {
    background: radial-gradient(circle at right bottom, transparent 75%, rgba(255, 255, 255, 0.05) 75%, transparent);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  #lightscape {
    background-image: url('images/LightscapeDesktop.png');
  }

  #explorations {
    background-image: url('images/ArtExplorationsInIrelandDesktop.png');
  }
  
  #marble-solitaire {
    box-shadow: 0px 0px 0px 0px rgba(59, 28, 28, 0);
  }

  #image-processor {
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.2);
  }

  .mobilePrototype {
    border: white
  }

  .project-shadow {
    box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.2);
  }

  .project img {
    justify-content: center;
    align-items: center;
  }

  #spreadsheetImage {
    margin-top: .6em;
  }

  .full-width {
    width: 100vw;
  }

@media only screen and (max-width: 1300px) {
    .right-col {
        grid-column: 1;
        grid-row: 2;
        margin-left: auto;
        margin-right: auto;        
        padding-top: 0em;
        top: -2em;
        padding: 8em, 0em;
    }
    .left-col {
        margin-left: auto;
        margin-right: auto;
        grid-column: 1;
        grid-row: 1;
        padding: 9em, 0em;
    }

    .projects {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .feature {
        margin: .2rem auto 0rem auto;

        font-size: 1.2rem;
        line-height: 100%;
        text-align: center;
        display: block;
        padding: 5%;
    
        width: 18em;
        height: 566px;
    }

    .greeting {
        font-size: 1.8em;
    }
}

@media only screen and (max-width: 1273px) {
    .threeCol {
        padding: 0 1% 0 1%;
        column-gap: 1%;
    }
}

@media only screen and (max-width: 1236px) {
    .project h6 {
        font-size: .8rem;
    }
    .project a {
        font-size: .8rem;
    }
    .project p {
        font-size: .8rem;
    }
    .project details {
        font-size: .8rem;
    }

    .featured-projects {
        grid-template-columns: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .feature {
        margin: 2rem auto 0rem auto;
        width: 25rem;
        height: 566px;
    }

    h4 {
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

@media only screen and (max-width: 1023px) {
    .threeCol {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        margin-top: 0;
    }
    .project h6 {
        font-size: 2rem;
    }
    .project a {
        font-size: 1rem;
    }
    .project p {
        font-size: 1rem;
    }
    .project details {
        font-size: 1rem;
    }

    iframe {
        width: 70%;
        height:30rem;
    }

    .subheading {
        display: block;
    }
}

@media only screen and (max-width: 1000px) {
    .bio {
        display: block;
        padding: 5em;
        padding-bottom: 4em;
    }

    .bio img {
        max-width: 40%;
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
    }
    .greeting {
        padding-top: 2em;
        padding-left: 0;
        display: block;
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .content {
        display: block;
    }
    .left-col {
        padding-top: 15em;
    }

    h2 {
        font-size: 1.8em;
    }
}

@media only screen and (max-width: 800px) {

    .side-buttons {
        display: block;
    }
    .about-me-button {
        padding-bottom: 2em;
    }
    .section-header {
        font-size: 5em;
    }

    .navigation {
        font-size: .9em;
    }

    li {
        margin: 0em .6em;
    }

    .greeting {
        font-size: 1.5em;
    }

    .bio {
        padding-bottom: 7.5em;
    }

    .left-col {
        padding-top: 10em;
    }

    h1 {
        font-size: 9em;
    }
}

@media only screen and (max-width: 552px) {
    .greeting {
        font-size: .9em;
    }

    .bio {
        padding-bottom: 9em;
    }

    .section-header {
        font-size: 3em;
        padding-top: 1em;
    }

    #projects-header {
        margin-bottom: -3em;
    }

    h1 {
        font-size: 5.5em;
    }

    h2 {
        font-size: 1em;
        padding-left: 2em;
        padding-right: 2em;
    }
}

@media only screen and (max-width: 375px) {
    .navigation {
        font-size: .8em;
    }

    h1 {
        font-size: 4em;
    }
}
